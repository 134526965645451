@import url("https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css");
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.cdnfonts.com/css/satoshi');

@font-face {
  font-family: Museo;
  src: url('/src/webfont/MuseoSansRounded300.otf');
}

@media (max-width: 500px) {
  html,
  body {
    height: auto;
    font-family: 'Satoshi', sans-serif;
  }
}

input[type='date'], input[type='time'] {
  /* -webkit-appearance: none; */
  -webkit-min-logical-width: calc(100% - 16px);
}

.mobile-nav {
  display: none;
  position: relative;
  top: -0.12rem;
  color:#fff;
}

@media (max-width: 1028px) {
  .mobile-nav {
    display: flex;
    align-items: center;
    gap:10px;
  }
  .mobile-nav p {
    font-size:12px;
    font-weight:500;
  }
}

/* =========================
Custom Input range 
=============================*/

/* Input range - chrome and safari */

input[type="range"] {
  --range-progress: 0;
  
  -webkit-appearance: none;
  position: relative;
  background:#ccc;
  width: 100%;
  top: -0.3rem;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* Input range - firefox */
input[type="range"]::-moz-range-track {
  position: relative;
  background:#ccc;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  cursor: pointer;
}

/* played progress length - Chrome & safari*/
input[type="range"]::before {
  content: '';
  height: 2px;
  background: #c82b32;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}

/* played progress length - firefox */
input[type="range"]::-moz-range-progress {
  background: #c82b32;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2px;
}

/* slider thumb - chrome and safari */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  background-color: #c82b32;
  cursor: pointer;
  position: relative;
}

/* dragging thumb - chrome and safari */
input[type="range"]:active::-webkit-slider-thumb {
  transform: scale(1.2);
}

/* slider thumb - firefox */
input[type="range"]::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #c82b32;;
  cursor: pointer;
  border: transparent;
  position: relative;
}
/* dragging thumb - firefox */
input[type="range"]:active::-moz-range-thumb {
  transform: scale(1.2);
}

progress[value] {
  --w: 100%; /* the width*/
  --color:  /* the progress color */
  #f3e1fc;
  --background: #fbf5ff; /* the background color */

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  width: var(--w);
  margin: 0 10px;
  border-radius: 10em;
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background);
  width: 100%;
}
progress[value]::-webkit-progress-value {
  width: 100%;
  border-radius: 10em;
  background: var(--color);
}
progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color);
  width: 100%;
}