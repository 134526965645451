@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Animations */
@keyframes pause {
  from {
    width: 13px;
    height: 13px;
    opacity: 0;
  }
  to {
    width: 20px;
    height: 20px;
    opacity: 1;
  }
}
