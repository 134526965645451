.active{
    background: #fff;
    color:#191919;
    border-radius: 10px;
}
.normal-text{
    color: #fff;
}

@media (max-width:728px){
    .active{
        color: #191919;
        background: #fff;
    }
    .normal-text{
        color: #fff;
    }
}